.preloader-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 500;

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: transparent;
		transition: opacity 1.1s ease-in-out; }

	&.is-hide {
		pointer-events: none;

		@media (min-width: 1200px) {
			.preloader-logo {
				transform: scale(.85); } } }

	&.hide-bg {
		&:before {
			opacity: 0; }

		.preloader {
			width: 0; } } }

.preloader-box,
.preloader {
	height: 100%; }

.preloader {
	background-color: #001747;
	width: 100%;
	height: 100%;
	position: relative;
	transition: width .6s ease-in-out;
	overflow: hidden;

	&-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center; }

	&-logo {
		margin-bottom: 50px;
		margin-top: -17px;
		transition: transform .5s ease-in-out;
		overflow: hidden;

		img {
			position: relative;
			top: -1px; }

		@media (max-width: 767px) {
			margin-top: -26px; } } }

@media (min-width: 1200px) {
	.loader-grey {
		position: fixed;
		top: 65px;
		right: 0;
		left: 0;
		z-index: 100;
		height: calc(100% - 64px);
		background-color: #fff;
		transition: opacity .4s ease-in-out;

		&.style_blue {
			background-color: transparent; }

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			transition: width .5s cubic-bezier(.01,0,.17,1);
			background-color: #d6dadf;
			width: 100%;
			z-index: 1;
			will-change: width; }

		&.is-hide {
			pointer-events: none; }

		&.hide-bg {
			animation: hideGreyBg .7s forwards ease-in-out;

			&:before {
				width: 0; } }

		&.style_blue {
			&:before {
				transition: width .7s cubic-bezier(.03,.03,.01,1); }

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				transition: width .7s cubic-bezier(.97,.01,.01,.97);
				background-color: #001747;
				width: 100%;
				will-change: width; }

			&.hide-bg {
				animation: hideGreyBg .7s forwards ease-in-out;

				&:after {
					width: 0; } } } } }

@keyframes hideGreyBg {
	50% {
		opacity: 1; }

	100% {
		opacity: 0; } }

.preloader-image {
	img {
		width: 100px; } }
